import {FunctionComponent, useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import {format, isAfter, parse} from "date-fns";
import userService from "../../../../services/userService";
import {IUserBan} from "../../../../types/UserBan";

const BanModal: FunctionComponent<{
    userId: string;
    currentBan?: IUserBan;
    isOpen: boolean;
    onClose: () => void;
}> = ({userId, isOpen, onClose, currentBan}) => {
    const [banDate, setBanDate] = useState<string>('');
    const [reason, setReason] = useState<string>('');

    useEffect(() => {
        if(currentBan) {
            const originalBanDateStr = currentBan.banUntil ? format(currentBan.banUntil.toDate(), 'yyyy-MM-dd') : '';
            setBanDate(originalBanDateStr);
            setReason(currentBan.reason ?? '');
        }
    }, [currentBan])

    async function banUser() {
        const parsedDate = parse(banDate, 'yyyy-MM-dd', new Date());
        await userService.banUser(userId, parsedDate, reason);
        onClose();
    }

    async function unbanUser() {
        await userService.unbanUser(userId);
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            sx={{'& .MuiDialog-paper': {width: '50%'}}}
        >
            <DialogTitle>Ban User</DialogTitle>
            <DialogContent>
                <Box sx={{mb: 2, mt: 2}}>
                    <TextField
                        label={'Ban Until'}
                        type={"date"}
                        value={banDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            setBanDate(e.target.value)
                        }}
                    />
                </Box>

                <TextField
                    fullWidth={true}
                    label={'Reason'}
                    value={reason}
                    onChange={(e) => {
                        setReason(e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between'}}>
                {currentBan && isAfter(currentBan.banUntil.toDate(), Date.now()) ? (
                    <Button onClick={unbanUser}>Unban</Button>
                ): <Box/>}

                <Box>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={banUser} color={'error'}>Ban</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default BanModal;