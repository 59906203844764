import {
    collection,
    CollectionReference,
    getDocs,
    orderBy,
    query,
    where,
    QuerySnapshot,
    doc,
    getDoc, updateDoc
} from "firebase/firestore";
import FirebaseService from "./firebaseService";
import {IUserReport} from "../types/UserReport";
import {Collection} from "../constants/Collection";


class UserReportService {
    ref: CollectionReference;

    constructor() {
        const db = FirebaseService.db;
        this.ref = collection(db, 'UserReport');
    }

    async getReport(reportId?: string): Promise<IUserReport | undefined> {
        const res = await getDoc(doc(this.ref, reportId));
        return res.exists() ? { id: res.id, ...res.data() } as IUserReport : undefined;
    }

    async getUnresolvedReports(): Promise<IUserReport[]> {
        const q = query(this.ref,
            where("isResolved", "==", false),
            orderBy("reportedOn", "asc")
        );

        const docs = await getDocs(q);

        return this._toUserReports(docs);
    }

    async getReportsForUser(userId: string) {
        const q = query(this.ref,
            where("reportedUserId", "==", userId),
            orderBy("reportedOn", "desc")
        );

        const docs = await getDocs(q);

        return this._toUserReports(docs);
    }

    async resolve(reportId?: string) {
        if(reportId) {
            await this._updateReport(reportId, {isResolved: true});
        }
    }

    private async _updateReport(reportId: string, data: Partial<IUserReport>) {
        const ref = doc(FirebaseService.db, Collection.USER_REPORT, reportId);
        await updateDoc(ref, data);
    }

    private _toUserReports(snapshots: QuerySnapshot) {
        return snapshots.docs.filter((doc) => doc.exists()).map((doc) => {
            return { ...doc.data(), id: doc.id } as IUserReport;
        });
    }
}

export default new UserReportService();