import useAuth from "../hooks/useAuth";
import Layout from "./common/Layout";
import {FunctionComponent} from "react";
import LoginRequired from "./common/LoginRequired";
import {Outlet} from "react-router";
import ConfirmDialogContextProvider from "../contexts/ConfirmDialogContext";

const AuthenticatedApp: FunctionComponent = () => {
    const {signOut} = useAuth();

    return (
        <LoginRequired>
            <ConfirmDialogContextProvider>
                <Layout signOut={signOut}>
                    <Outlet/>
                </Layout>
            </ConfirmDialogContextProvider>
        </LoginRequired>
    );
};

export default AuthenticatedApp;