import {
    Box, Button, Card, IconButton, Link as MuiLink,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import activationService from "../../../services/activationService";
import {IActivation} from "../../../types/Activation";
import {useNavigate} from "react-router";
import {Delete} from "@mui/icons-material";
import {useConfirmDialog} from "../../../hooks/useConfirmDialog";
import {Link} from "react-router-dom";


export const ActivationList: React.FC<{
    pageSize?: 10 | 25 | 50 | 100;
}> = ({pageSize = 25}) => {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [activations, setActivations] = useState<IActivation[]>([]);
    const {openConfirmDialog} = useConfirmDialog();

    async function getActivations() {
        const activations = await activationService.getActivations();

        setActivations(activations);
    }

    async function deleteActivation(activationId?: string) {
        void openConfirmDialog(async () => {
            await activationService.deleteActivation(activationId);
            void getActivations();
        });
    }

    useEffect(() => {
        void getActivations();
    }, []);

    return (
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant={"h1"} sx={{mb: 4}}>
                    Activations
                </Typography>

                <Button
                    variant={'contained'}
                    onClick={() => navigate('/activations/add')}
                >Add activation</Button>
            </Box>


            <Card sx={{mb: 4}}>
                <Box sx={{width: '100%'}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activations.slice(page * pageSize, page * pageSize + pageSize).map((row) => (
                                    <TableRow key={row.id}
                                              component={Link}
                                              to={`/activations/${row.id}`} role={"link"}
                                              hover={true}
                                              sx={{'cursor': 'pointer', 'textDecoration': 'none'}}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>
                                            {row.url ? (<MuiLink href={row.url} onClick={(e) => e.stopPropagation()} target={"_blank"}>{row.title}</MuiLink>) : row.title}
                                        </TableCell>
                                        <TableCell>{`${row.location.address}, ${row.location.city}, ${row.location.state} ${row.location.zip}`}</TableCell>
                                        <TableCell>
                                            <span>{row.datetime?.toDate().toLocaleString() ?? ''}</span>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={(e) => {
                                                e.stopPropagation();
                                                void deleteActivation(row.id);
                                            }}>
                                                <Delete/>
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component={"div"}
                        count={activations.length}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={(event, newPage) => setPage(newPage)}/>
                </Box>
            </Card>
        </Box>
    );
}