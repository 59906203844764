import {FunctionComponent, useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    TextField,
} from "@mui/material";
import userService from "../../../../services/userService";
import {IRequestedChange} from "../../../../types/RequestedChange";

interface IChange {
    selected?: boolean;
    reason?: string;
}

const RequestChangesModal: FunctionComponent<{
    userId: string;
    currentChanges?: IRequestedChange[];
    isOpen: boolean;
    onClose: () => void;
}> = ({userId, isOpen, onClose, currentChanges}) => {
    const [changes, setChanges] = useState<Record<string, IChange | undefined>>({});

    async function requestChanges() {
        const requestedChanges: IRequestedChange[] = Object.keys(changes).filter((key) => changes[key]?.selected === true).map((key) => {
            return {
                field: key,
                reason: changes[key]?.reason ?? ''
            };
        });

        await userService.requestChanges(userId, requestedChanges);
        onClose();
    }

    useEffect(() => {
        if(currentChanges) {
            setChanges(currentChanges.reduce<Record<string, IChange | undefined>>((obj, change) => {
                obj[change.field] = { selected: true, reason: change.reason };

                return obj;
            }, {}));
        }
    }, [currentChanges]);

    return (
        <Dialog
            open={isOpen}
            sx={{'& .MuiDialog-paper': {width: '50%'}}}
        >
            <DialogTitle>Request Changes</DialogTitle>
            <DialogContent>
                <Box sx={{mb: 2, mt: 2}}>
                    <Box sx={{mb: 1}}>
                        <FormControlLabel
                            control={<Checkbox checked={changes.name?.selected === true}/>}
                            label={"Name"}
                            value={changes.name?.selected === true}
                            onChange={() => {
                                setChanges({
                                    ...changes,
                                    name: { selected: !changes.name?.selected}
                                });
                            }} />
                    </Box>

                    <Box>
                        <TextField
                            fullWidth={true}
                            label={'Reason'}
                            value={changes.name?.reason ?? ''}
                            onChange={(e) => {
                                setChanges({
                                    ...changes,
                                    name: { selected: true, reason: e.target.value }
                                })
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{mb: 2}}>
                    <Box sx={{mb: 1}}>
                        <FormControlLabel
                            control={<Checkbox checked={changes.birthday?.selected === true}/>}
                            label={"Birthday"}
                            value={changes.birthday?.selected === true}
                            onChange={() => {
                                setChanges({
                                    ...changes,
                                    birthday: { selected: !changes.birthday?.selected}
                                });
                            }} />
                    </Box>

                    <Box>
                        <TextField
                            fullWidth={true}
                            label={'Reason'}
                            value={changes.birthday?.reason ?? ''}
                            onChange={(e) => {
                                setChanges({
                                    ...changes,
                                    birthday: { selected: true, reason: e.target.value }
                                })
                            }}
                        />
                    </Box>
                </Box>


                <Box sx={{mb: 4}}>
                    <Box sx={{mb: 1}}>
                        <FormControlLabel
                            control={<Checkbox checked={changes.imageList?.selected === true}/>}
                            label={"Photos"}
                            value={changes.imageList?.selected === true}
                            onChange={() => {
                                setChanges({
                                    ...changes,
                                    imageList: { selected: !changes.imageList?.selected}
                                });
                            }} />
                    </Box>

                    <Box>
                        <TextField
                            fullWidth={true}
                            label={'Reason'}
                            value={changes.imageList?.reason ?? ''}
                            onChange={(e) => {
                                setChanges({
                                    ...changes,
                                    imageList: { selected: true, reason: e.target.value }
                                })
                            }}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions style={{justifyContent: 'flex-end'}}>
                <Button onClick={onClose} sx={{ mr: 1}}>Cancel</Button>
                <Button onClick={requestChanges}
                        color={'warning'}
                        variant={'contained'}
                        disabled={Object.values(changes).filter(change => change?.selected).length === 0}>
                    Request Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default RequestChangesModal;