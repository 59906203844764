import {FunctionComponent, useEffect, useState} from "react";
import userReportService from "../../../services/userReportService";
import {IUserReport} from "../../../types/UserReport";
import {IUserModel} from "../../../types/UserModel";
import userService from "../../../services/userService";
import {
    Alert,
    Box,
    Card, Grid,
    ImageList,
    ImageListItem,
    Typography,
} from "@mui/material";
import ReportList from "../../common/ReportList";
import {IScreenerQuestion} from "../../../types/ScreenerQuestion";
import ScreenerQuestionList from "../../common/ScreenerQuestionList";
import {useConfirmDialog} from "../../../hooks/useConfirmDialog";
import BanModal from "../UserDetail/components/BanModal";
import {IUserBan} from "../../../types/UserBan";
import {differenceInYears, isAfter} from "date-fns";
import {ProfileInformation} from "./components/ProfileInformation";
import {useParams} from "react-router";
import RequestChangesModal from "./components/RequestChangesModal";
import {IRequestedChange} from "../../../types/RequestedChange";
import {ImageHover} from "./components/ImageHover";
import userVerificationService from "../../../services/userVerificationService";
import {IUserVerification} from "../../../types/UserVerification";
import {UserActions} from "./components/UserActions";
import { CircleProfileImage } from "./components/CircleProfileImage";
import {CoverPhoto} from "./components/CoverPhoto";
import {IUserProfile} from "../../../types/UserProfile";
import {PromptList} from "./components/PromptList";


const UserDetailContent: FunctionComponent<{ userId?: string }> = ({userId}) => {
    const params = useParams<{ userId: string }>();

    if (!userId) {
        userId = params.userId;
    }
    const [user, setUser] = useState<IUserModel | undefined>();
    const [screenerQuestions, setScreenerQuestions] = useState<IScreenerQuestion[]>([]);
    const [userReports, setUserReports] = useState<IUserReport[]>([]);
    const [isBanModalOpen, setIsBanModalOpen] = useState(false);
    const [isRequestChangesModalOpen, setIsRequestChangesModalOpen] = useState(false);
    const [userBan, setUserBan] = useState<IUserBan | undefined>();
    const [requestedChanges, setRequestedChanges] = useState<IRequestedChange[]>([]);
    const [userVerification, setUserVerification] = useState<IUserVerification>();
    const {openConfirmDialog} = useConfirmDialog();

    async function getUser(userId: string) {
        const res = await userService.getUser(userId);
        setUser(res);
    }

    async function getUserScreenerQuestions(userId: string) {
        const res = await userService.getScreenerQuestions(userId);
        setScreenerQuestions(res);
    }

    async function getUserReports(userId: string) {
        const res = await userReportService.getReportsForUser(userId);
        setUserReports(res);
    }

    async function getUserBan(userId: string) {
        const res = await userService.getUserBan(userId);
        setUserBan(res);
    }

    async function getRequestedChanges(userId: string) {
        const res = await userService.getRequestedChanges(userId);
        setRequestedChanges(res);
    }

    async function getUserVerification(userId: string) {
        const res = await userVerificationService.getUserVerification(userId);
        setUserVerification(res);
    }

    function updateUserProfile(changes: Partial<IUserProfile>) {
        if(user) {
            setUser({
                ...user,
                profile: {
                    ...user.profile,
                    ...changes
                },
            });
        }
    }

    useEffect(() => {
        void getUser(userId!);
        void getUserReports(userId!);
        void getUserScreenerQuestions(userId!);
        void getRequestedChanges(userId!);
        void getUserVerification(userId!);
    }, []);

    useEffect(() => {
        if (!isBanModalOpen) {
            void getUserBan(userId!);
        }
    }, [isBanModalOpen]);

    return (
        <Box>
            {!!userBan && isAfter(userBan.banUntil.toDate(), Date.now()) && (
                <Alert severity={"error"} variant={'filled'} sx={{mb: 2}}>
                    User Banned until {userBan.banUntil.toDate().toLocaleString()} due to: {userBan.reason}
                </Alert>
            )}

            <Typography variant={'h1'}
                        sx={{mb: 4}}>{user?.profile.name}, {user?.profile.birthday && differenceInYears(Date.now(), user?.profile.birthday?.toDate())}</Typography>

            <Grid container spacing={2} direction={{ xs: 'column-reverse', lg: 'row'}}>
                <Grid item lg={10} xs={12}>
                    {user && (
                        <ProfileInformation userId={user.id} profile={user.profile} onChange={(profile) => {
                            setUser({
                                ...user,
                                profile: profile
                            })
                        }}/>
                    )}

                    <Grid container spacing={2} sx={{mb: 4}}>
                        <Grid item lg={userVerification ? 6 : 12} xs={12}>
                            <Card sx={{p: 2}}>
                                <Typography variant={'h2'} sx={{mb: 2}}>Photos</Typography>

                                {user && (
                                    <CircleProfileImage
                                        userId={user.id}
                                        circleProfilePhoto={user.profile.circleProfilePhoto}
                                        onChange={() => {
                                            updateUserProfile({ circleProfilePhoto: '' });
                                        }}
                                    />
                                )}


                                {user && (
                                    <CoverPhoto
                                        userId={user.id}
                                        coverPhoto={user.profile.coverPhoto}
                                        onChange={() => {
                                            updateUserProfile({ coverPhoto: '' });
                                        }}
                                    />
                                )}

                                {user && (
                                    <ImageList cols={3}>
                                        {user.profile.imageList.map((image, idx) => (
                                            <ImageListItem key={idx}>
                                                {!!image && (
                                                    <ImageHover
                                                        onClick={() => {
                                                            void openConfirmDialog(async () => {
                                                                const removeImages = user.profile.imageList.splice(idx, 1);
                                                                await userService.deleteUserImage(user.id, user.profile.imageList, removeImages[0]);
                                                            });
                                                        }}
                                                    />
                                                )}

                                                {!image && (
                                                    <Box sx={{bgcolor: 'grey.300', width: '100%', height: '100%'}}/>
                                                )}

                                                <img src={image} alt=""/>
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                )}
                            </Card>
                        </Grid>

                        {userVerification && (
                            <Grid item lg={6} xs={12}>
                                <Card sx={{p: 2}}>
                                    <Typography variant={"h2"}>Verification Photo</Typography>

                                    <ImageListItem>
                                        <img src={userVerification?.verificationImage} alt=""/>
                                    </ImageListItem>
                                </Card>
                            </Grid>
                        )}
                    </Grid>

                    {user && (
                        <PromptList
                            prompts={user.profile.prompts}
                            onRemove={async (prompt) => {
                                const newPrompts = user.profile?.prompts?.filter((item) => item !== prompt) ?? [];

                                await userService.deletePrompt(user.id, newPrompts);
                                updateUserProfile({ prompts: newPrompts });
                            }}
                        />
                    )}

                    {!!screenerQuestions.length && (
                        <Card sx={{mb: 4}}>
                            <Typography variant={'h2'} sx={{m: 2}}>Screener Questions</Typography>

                            <ScreenerQuestionList screenerQuestions={screenerQuestions} onRemove={(sq) => {
                                openConfirmDialog(async () => {
                                    if (user?.id) {
                                        await userService.deleteScreenerQuestion(user.id, sq.id);
                                        setScreenerQuestions(screenerQuestions.filter((question) => question.id !== sq.id));
                                    }

                                })
                            }}/>
                        </Card>
                    )}

                    <Card sx={{mb: 4}}>
                        <Typography variant={"h2"} sx={{m: 2}}>Reports</Typography>
                        <ReportList pageSize={10} reports={userReports}/>
                    </Card>

                    <BanModal currentBan={userBan} userId={userId!} isOpen={isBanModalOpen}
                              onClose={() => setIsBanModalOpen(false)}/>

                    <RequestChangesModal userId={userId!} isOpen={isRequestChangesModalOpen}
                                         currentChanges={requestedChanges}
                                         onClose={() => {
                                             setIsRequestChangesModalOpen(false);
                                             void getUser(userId!);
                                         }}/>
                </Grid>

                <Grid item lg={2} xs={12}>
                    <UserActions
                        userId={userId}
                        user={user}
                        setUser={setUser}
                        userVerification={userVerification}
                        setUserVerification={setUserVerification}
                        onBan={() => setIsBanModalOpen(true)}
                        onRequestChanges={() => setIsRequestChangesModalOpen(true)}
                    />
                </Grid>

            </Grid>
        </Box>
    )
}

export default UserDetailContent;