import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {AuthContextProvider} from './contexts/AuthContext';
import {CssBaseline, ThemeProvider} from '@mui/material';
import theme from './constants/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <AuthContextProvider>
              <ThemeProvider theme={theme}>
                  <CssBaseline>
                    <App />
                  </CssBaseline>
              </ThemeProvider>
          </AuthContextProvider>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
