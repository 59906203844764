import {FunctionComponent, PropsWithChildren, useEffect} from "react";
import {redirect} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {UserRole} from "../../constants/UserRole";


const LoginRequired: FunctionComponent<PropsWithChildren> = ({children}) => {

    const { role, hasLoaded } = useAuth();

    useEffect(() => {
        if(role !== UserRole.ADMIN && hasLoaded) {
            redirect('/login');
        }
    }, [role, hasLoaded]);

    if(!hasLoaded) {
        return <></>;
    }

    return (
        <>
            {children}
        </>
    );
}

export default LoginRequired;