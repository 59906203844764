import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    updateDoc,
    Timestamp,
    setDoc,
    CollectionReference, query, orderBy, QuerySnapshot, where
} from "firebase/firestore";
import {getStorage, ref, deleteObject} from "firebase/storage";
import FirebaseService from "./firebaseService";
import {UserRole} from "../constants/UserRole";
import {IUserModel} from "../types/UserModel";
import {IScreenerQuestion} from "../types/ScreenerQuestion";
import {sendUserNotification} from "../utils/notificationUtils";
import {NotificationType} from "../constants/NotificationType";
import {IUserProfile} from "../types/UserProfile";
import {Collection} from "../constants/Collection";
import {getAuth} from "firebase/auth";
import {IUserBan} from "../types/UserBan";
import {ProfileFields} from "../constants/ProfileFields";
import {IRequestedChange} from "../types/RequestedChange";
import {IPrompt} from "../types/Prompt";

class UserService {
    ref: CollectionReference;
    profileRef: CollectionReference;

    constructor() {
        const db = FirebaseService.db;
        this.ref = collection(db, 'Users');
        this.profileRef = collection(db, 'UserProfile');
    }

    async getNewUsers(): Promise<IUserModel[]> {
        let q = query(this.ref,
            where('isReviewed', '!=', true),
            where('isOnboardingComplete', '==', true),
            orderBy('isReviewed', 'asc'),
            orderBy('lastLoginTime', 'asc')
        );

        const docs = await getDocs(q);
        return this._toUsers(docs);
    }

    async getUserProfiles(search?: string): Promise<IUserProfile[]> {
        let q = query(this.profileRef);

        if (search?.length) {
            if (search.length >= 28) {
                const userDoc = await getDoc(doc(this.profileRef, search));

                if (userDoc.exists()) {
                    return [{id: userDoc.id, ...userDoc.data()} as IUserProfile];
                }
            }

            const frontCode = search?.slice(0, search.length - 1);
            const lastChar = search?.slice(search.length - 1, search.length);
            const endCode = frontCode + String.fromCharCode(lastChar.charCodeAt(0) + 1);

            q = query(q,
                where('name', '>=', frontCode),
                where('name', '<', endCode),
            );
        }

        q = query(q, orderBy('name', "asc"));

        const docs = await getDocs(q);

        return this._toUserProfiles(docs);
    }

    async getUser(userId: string): Promise<IUserModel | undefined> {
        const userSnapshot = await getDoc(doc(FirebaseService.db, Collection.USER, userId));

        if (userSnapshot.exists()) {
            const data = userSnapshot.data();
            const profileSnapshot = await getDoc(data.profile);
            const profile = profileSnapshot.data() as IUserProfile;

            return {
                ...data,
                id: userSnapshot.id,
                profile,
            };
        }

        return undefined;
    }

    async getRole(userId: string): Promise<UserRole> {
        const roleSnapshot = await getDoc(doc(FirebaseService.db, Collection.USER_ROLE, userId));

        if (roleSnapshot.exists()) {
            return roleSnapshot.get('role') as UserRole;
        }

        return UserRole.NONE;
    }

    async getScreenerQuestions(userId: string): Promise<IScreenerQuestion[]> {
        const res = await getDocs(collection(FirebaseService.db, Collection.USER, userId, Collection.SCREENER_QUESTION));
        return res.docs.filter((doc) => doc.exists() && !doc.get('isDeleted')).map((doc) => ({id: doc.id, ...doc.data()}) as IScreenerQuestion);
    }

    async deleteCoverPhoto(userId: string, removedImage: string) {
        const userProfileRef = doc(FirebaseService.db, Collection.USER_PROFILE, userId);
        const storage = getStorage(FirebaseService.app);
        const imageRef = ref(storage, removedImage);

        await updateDoc(userProfileRef, {coverPhoto: ''});
        await deleteObject(imageRef);
        await sendUserNotification(userId, {
            title: 'Cover photo removed',
            message: 'Your cover photo has been removed from your profile due to violating our guidelines.',
            data: {
                type: NotificationType.IMAGE_REMOVED
            }
        });
    }

    async deleteCircleProfilePhoto(userId: string, removedImage: string) {
        const userProfileRef = doc(FirebaseService.db, Collection.USER_PROFILE, userId);
        const storage = getStorage(FirebaseService.app);
        const imageRef = ref(storage, removedImage);

        await updateDoc(userProfileRef, {circleProfilePhoto: ''});
        await deleteObject(imageRef);
        await sendUserNotification(userId, {
            title: 'Profile photo removed',
            message: 'Your profile photo has been removed due to violating our guidelines.',
            data: {
                type: NotificationType.IMAGE_REMOVED
            }
        });
    }

    async deleteUserImage(userId: string, imageList: string[], removedImage: string) {
        const userProfileRef = doc(FirebaseService.db, Collection.USER_PROFILE, userId);
        const storage = getStorage(FirebaseService.app);
        const imageRef = ref(storage, removedImage);

        await updateDoc(userProfileRef, {imageList});
        await deleteObject(imageRef);
        await sendUserNotification(userId, {
            title: 'Image removed',
            message: 'An image has been removed from your profile due to violating our guidelines.',
            data: {
                type: NotificationType.IMAGE_REMOVED
            }
        });
    }

    async deletePrompt(userId: string, prompts: IPrompt[]) {
        const userProfileRef = doc(FirebaseService.db, Collection.USER_PROFILE, userId);
        await updateDoc(userProfileRef, {prompts});
    }

    async deleteScreenerQuestion(userId: string, questionId: string) {
        const ref = doc(FirebaseService.db, Collection.USER, userId, Collection.SCREENER_QUESTION, questionId);

        await deleteDoc(ref);
        await sendUserNotification(userId, {
            title: 'Screener Question removed',
            message: 'A screener question has been removed from your profile due to violating our guidelines.',
            data: {
                type: NotificationType.SCREENER_QUESTION_REMOVED
            }
        })
    }

    async deleteProfileInfo(userId: string, field: ProfileFields) {
        const ref = doc(FirebaseService.db, Collection.USER_PROFILE, userId);
        await updateDoc(ref, {[field]: ''});
        await sendUserNotification(userId, {
            title: 'Profile modified by an admin',
            message: 'Your profile has been modified by an admin due to violating our guidelines.',
            data: {
                type: NotificationType.BIO_REMOVED
            }
        });
    }

    async requestChanges(userId: string, changes: IRequestedChange[]) {
        if(userId) {
            const ref = doc(FirebaseService.db, Collection.USER, userId);
            await updateDoc(ref, {
                isReviewed: true,
                isApproved: false,
                changesRequested: true
            });

            const changeCollectionRef = doc(ref, Collection.REQUESTED_CHANGES, userId);

            await setDoc(changeCollectionRef, { changes });

            void sendUserNotification(userId, {
                title: 'Your profile needs to be updated',
                message: 'A Skip admin has requested some changes to your profile. Tap here to review and update.',
                data: {
                    type: NotificationType.CHANGES_REQUESTED
                }
            })
        }
    }

    async toggleUserReviewed(userId: string, newValue: boolean): Promise<Partial<IUserModel>> {
        if(userId) {
            const ref = doc(FirebaseService.db, Collection.USER, userId);

            const data: Partial<IUserModel> = { isReviewed: newValue };
            await updateDoc(ref, data);

            return data;
        }

        return {};
    }

    async toggleUserApproval(userId: string, newValue: boolean, shouldNotify?: boolean): Promise<Partial<IUserModel>> {
        if(userId) {
            const ref = doc(FirebaseService.db, Collection.USER, userId);

            const data: Partial<IUserModel> = {
                isReviewed: true,
                isApproved: newValue
            };

            if(newValue) {
                data.changesRequested = false;
            }

            await updateDoc(ref, data);

            if(newValue && shouldNotify) {
                await sendUserNotification(userId, {
                    title: 'Your profile has been approved!',
                    message: 'Now it\'s time to book a date',
                    data: {
                        type: NotificationType.PROFILE_APPROVED
                    }
                });
            }

            return data;
        }

        return {};
    }

    async banUser(userId: string, banUntil: Date, reason: string) {
        const ref = doc(FirebaseService.db, Collection.USER_BAN, userId);
        await setDoc(ref, {
            banUntil: Timestamp.fromDate(banUntil),
            bannedBy: getAuth().currentUser?.uid,
            reason,
        } as IUserBan);
        await sendUserNotification(userId, {
            title: 'You have been temporarily banned',
            message: `You have been temporarily banned due to: ${reason}`,
            data: {
                type: NotificationType.BANNED
            }
        });
    }

    async unbanUser(userId: string) {
        const ref = doc(FirebaseService.db, Collection.USER_BAN, userId);
        await setDoc(ref, {
            banUntil: Timestamp.fromDate(new Date()),
            bannedBy: getAuth().currentUser?.uid,
            reason: ''
        });

        await sendUserNotification(userId, {
            title: 'Your ban has been lifted',
            message: 'Your ban has been lifted, you are once again able to use Skip'
        });
    }

    async getUserBan(userId: string): Promise<IUserBan | undefined> {
        const ref = doc(FirebaseService.db, Collection.USER_BAN, userId);
        const res = await getDoc(ref);

        if (res.exists()) {
            return res.data() as IUserBan;
        }

        return undefined;
    }

    async getRequestedChanges(userId: string): Promise<IRequestedChange[]> {
        const ref = doc(FirebaseService.db, Collection.USER, userId, Collection.REQUESTED_CHANGES, userId);
        const snapshot = await getDoc(ref);

        if(snapshot.exists()) {
            return snapshot.get('changes') as IRequestedChange[];
        }

        return [];
    }

    private _toUserProfiles(snapshots: QuerySnapshot): IUserProfile[] {
        return snapshots.docs.filter((doc) => doc.exists()).map((doc) => {
            return {...doc.data(), id: doc.id} as IUserProfile;
        })
    }

    private _toUsers(snapshots: QuerySnapshot): IUserModel[] {
        return snapshots.docs.filter((doc) => doc.exists()).map((doc) => {
            return {...doc.data(), id: doc.id} as IUserModel;
        })
    }

}

export default new UserService();