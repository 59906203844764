import {createContext, FunctionComponent, PropsWithChildren, useState} from "react";
import ConfirmDialog from "../components/common/ConfirmDialog";

export const ConfirmDialogContext = createContext({
    dialog: {
        isOpen: false,
        cb: (res?: boolean) => {}
    },
    openConfirmDialog: (onConfirm: () => void, onCancel?: () => void) => {}
})

const ConfirmDialogContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [dialog, setDialog] = useState({
        isOpen: false,
        cb: (res?: boolean) => {}
    });

    function openConfirmDialog(onConfirm: () => void, onCancel: () => void = () => {}) {
        setDialog({
            isOpen: true,
            cb: (res) => {
                res ? onConfirm() : onCancel();
                setDialog({
                    isOpen: false,
                    cb: () => {}
                });
            }
        })
    }

    return (
        <ConfirmDialogContext.Provider value={{ dialog, openConfirmDialog}}>
            {children}

            <ConfirmDialog
                isOpen={dialog.isOpen}
                onClose={dialog.cb}/>
        </ConfirmDialogContext.Provider>
    )
}

export default ConfirmDialogContextProvider;