import {FunctionComponent} from "react";
import {Box} from "@mui/material";
import {ImageHover} from "./ImageHover";
import userService from "../../../../services/userService";
import {useConfirmDialog} from "../../../../hooks/useConfirmDialog";

export const CircleProfileImage: FunctionComponent<{userId: string; circleProfilePhoto?: string; onChange: () => void}> = ({ userId, circleProfilePhoto, onChange}) => {
    const { openConfirmDialog } = useConfirmDialog();

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', mb: 4}}>
            <Box sx={{
                width: 256,
                height: 256,
                borderRadius: '50%',
                overflow: 'hidden',
                backgroundColor: 'grey.300',
                position: 'relative',
            }}
            >
                {circleProfilePhoto && (
                    <ImageHover
                        onClick={() => {
                            void openConfirmDialog(async () => {
                                await userService.deleteCircleProfilePhoto(userId, circleProfilePhoto);
                                onChange();
                            });
                        }}
                    />
                )}


                {circleProfilePhoto && (
                    <img width={'100%'} height={'100%'} src={circleProfilePhoto}
                         alt=""/>
                )}
            </Box>

        </Box>
    );
}