import {
    Box, Card, Link as MuiLink,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Checkbox,
    FormControlLabel,
    Select, 
    MenuItem,
    SelectChangeEvent
} from "@mui/material";
import React, {useEffect, useState} from "react";
import dateCardsService from "../../../services/dateCardsService";
import {IDateCardOrder} from "../../../types/DateCardOrders";
import {useNavigate} from "react-router";
import {useConfirmDialog} from "../../../hooks/useConfirmDialog";
import { formatTimestamp } from "../../../utils/timestampFormatter";



export const DateCardsOrdersList: React.FC<{
    pageSize?: 10 | 25 | 50 | 100;
}> = ({pageSize = 25}) => {
    const [page, setPage] = useState(0);
    const [dateCardOrders, setDateCardOrders] = useState<IDateCardOrder[]>([]);
    const [checkbox1Checked, setCheckboxValues] = useState<{ [key: string]: boolean }>({});
    const [dropdownValues, setDropdownValues] = useState<{ [key: string]: string }>({});

    const handleDeliveredClick = async (event: React.ChangeEvent<HTMLInputElement>, orderId: string, dateCardDocId: string) => {
        const checked = event.target.checked;
        // Update the checkbox value for the specific order
        setCheckboxValues(prevState => ({
            ...prevState,
            [orderId]: checked
        }));

        // Assuming you're updating Firestore with the checkbox status
        await dateCardsService.updateOrderStatus(orderId, dateCardDocId, checked ? 'delivered' : '');
    };

    const handlePendingRequestClick = async (event: SelectChangeEvent<string>, orderId: string, dateCardOrderId: string) => {
        const selectedValue = event.target.value;
        setDropdownValues(prevState => ({
            ...prevState,
            [orderId]: selectedValue
        }));
        await dateCardsService.updateOrderStatus(orderId, dateCardOrderId, selectedValue);
    };

    async function getDateCardOrders() {
        const dateCardOrders = await dateCardsService.getDateCardOrders();
        // Initialize dropdown values with the orderStatus from Firestore
        const initialDropdownValues: { [key: string]: string } = {};
        const intitalCheckboxValues: { [key: string]: boolean } = {};
        dateCardOrders.forEach(order => {
            if (order.orderStatus === 'delivered') {
                intitalCheckboxValues[order.orderID] = true;
                initialDropdownValues[order.orderID] = '';
            } else {
                intitalCheckboxValues[order.orderID] = false;
                initialDropdownValues[order.orderID] = order.orderStatus || ''; // Set to '' if orderStatus is undefined
            }
        });
        setDropdownValues(initialDropdownValues);
        setCheckboxValues(intitalCheckboxValues);
        setDateCardOrders(dateCardOrders);
    }

    useEffect(() => {
        void getDateCardOrders();
    }, []);

    return (
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant={"h1"} sx={{mb: 4}}>
                    Date Card Orders
                </Typography>
            </Box>


            <Card sx={{mb: 4}}>
                <Box sx={{width: '100%'}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Color</TableCell>
                                    <TableCell>Card intro message</TableCell>
                                    <TableCell>Card name</TableCell>
                                    <TableCell>Order created</TableCell>
                                    <TableCell>Profile Url</TableCell>
                                    <TableCell>Order status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dateCardOrders
                                    .sort((a, b) => {
                                        const dateA = a.timeStamp ? new Date(a.timeStamp).getTime() : new Date().getTime();
                                        const dateB = b.timeStamp ? new Date(b.timeStamp).getTime() : new Date().getTime();
                                        return dateA - dateB;
                                    }) // Sort by timestamp (oldest to newest)
                                    .slice(page * pageSize, page * pageSize + pageSize).map((row) => (
                                        <TableRow key={row.orderID}
                                            sx={{ 'cursor': 'default', 'textDecoration': 'none' }}>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.address != null ? row.address : 'null'}</TableCell>
                                            <TableCell>{row.cardColor}</TableCell>
                                            <TableCell>{row.cardMessage}</TableCell>
                                            <TableCell>{row.cardName}</TableCell>
                                            <TableCell>{formatTimestamp(row.timeStamp)}</TableCell>
                                            <TableCell><MuiLink href={row.profileUrl} onClick={(e) => e.stopPropagation()} target={row.profileUrl}>{row.profileUrl}</MuiLink></TableCell>
                                            <TableCell>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkbox1Checked[row.orderID] || false}
                                                            onChange={(e) => handleDeliveredClick(e, row.orderID, row.dateCardDocId)}
                                                            inputProps={{ 'aria-label': 'Delivered' }}
                                                        />
                                                    }
                                                    label="Delivered"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Select
                                                            value={dropdownValues[row.orderID]}
                                                            onChange={(e) => handlePendingRequestClick(e, row.orderID, row.dateCardDocId)}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Pending Requests' }}
                                                        >
                                                            <MenuItem value="">Pending Request</MenuItem>
                                                            <MenuItem value="address-issue">Address issue</MenuItem>
                                                            <MenuItem value="nickname">Nickname</MenuItem>
                                                            <MenuItem value="card-intro">Card intro</MenuItem>
                                                            <MenuItem value="fullname">Full name</MenuItem>
                                                            <MenuItem value="other">Other</MenuItem>
                                                        </Select>
                                                    }
                                                    label=""
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component={"div"}
                        count={dateCardOrders.length}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={(event, newPage) => setPage(newPage)}/>
                </Box>
            </Card>
        </Box>
    );
}