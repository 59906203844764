
export enum Collection {
    USER = 'Users',
    USER_DATE = 'UserDate',
    USER_MATCH = 'UserMatch',
    USER_SWIPE = 'UserSwipe',
    NOTIFICATIONS = 'Notifications',
    USER_REPORT = 'UserReport',
    USER_SWIPE_CACHE = 'UserSwipeCache',
    SWIPE_LIST = 'SwipeList',
    USER_ROLE = 'UserRole',
    USER_PROFILE = 'UserProfile',
    SCREENER_QUESTION = 'ScreenerQuestion',
    DATE_CARD_ORDERS = 'DateCardOrders',
    USER_BAN = 'UserBan',
    REQUESTED_CHANGES = 'RequestedChanges',
    USER_VERIFICATION = 'UserVerification'
}
