import logo from '../../images/logo.svg';
import GoogleSignInButton from "../common/GoogleSignInButton";
import {Navigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {Alert, Box, Snackbar} from "@mui/material";
import {FunctionComponent} from "react";
import {UserRole} from "../../constants/UserRole";

const Login: FunctionComponent = () => {
    const {user, role, hasLoaded, signOut} = useAuth();

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Snackbar
                autoHideDuration={6000}
                onClose={() => signOut()}
                open={!!user && role !== UserRole.ADMIN}>
                <Alert severity={"error"}>User does not have permission</Alert>
            </Snackbar>


                <header className="App-header">
                    <Box sx={{mb: 4}}>
                    <img src={logo} className="App-logo" alt="logo" width={"256px"} height={"256px"} />
                    </Box>
                </header>



            <GoogleSignInButton/>

            {role === UserRole.ADMIN && hasLoaded && <Navigate to={'/'} replace={true}/>}
        </Box>
    );
}

export default Login;