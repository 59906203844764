import {FunctionComponent, useEffect, useState} from "react";
import userService from "../../services/userService";
import {
    Box,
    Card,
    Typography
} from "@mui/material";
import {UserList} from "../common/UserList";
import {IUserModel} from "../../types/UserModel";

export const UserReviewList: FunctionComponent = () => {
    const [users, setUsers] = useState<IUserModel[]>([]);

    async function getUsers() {
        const res = await userService.getNewUsers();
        setUsers(res);
    }

    useEffect(() => {
        void getUsers();
    }, []);

    return (
        <Box>
            <Typography variant={'h1'} sx={{mb: 4}}>
                Review New Users
            </Typography>

            <Card>
                <UserList users={users} showName={false} showLoginTime={true} to={"user-review"}/>
            </Card>
        </Box>
    )
}