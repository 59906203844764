import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {FunctionComponent} from "react";
import {IScreenerQuestion} from "../../types/ScreenerQuestion";


const ScreenerQuestionList: FunctionComponent<{
    screenerQuestions: IScreenerQuestion[];
    onRemove?: (question: IScreenerQuestion) => void;
}> = ({screenerQuestions, onRemove}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Question</TableCell>
                        <TableCell>Answer</TableCell>
                        {onRemove && (
                            <TableCell></TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {screenerQuestions.map((sq) => (
                        <TableRow key={sq.id}>
                            <TableCell>{sq.question}</TableCell>
                            <TableCell>{sq.answer ? 'yes' : 'no'}</TableCell>
                            {onRemove && (
                                <TableCell>
                                    <Button variant={'outlined'} onClick={() => onRemove(sq)} color={'error'}>Remove</Button>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ScreenerQuestionList;