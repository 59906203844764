import {Box, Button, Card} from "@mui/material";
import userService from "../../../../services/userService";
import {IUserModel} from "../../../../types/UserModel";
import userVerificationService from "../../../../services/userVerificationService";
import {IUserVerification} from "../../../../types/UserVerification";
import {FunctionComponent} from "react";


export const UserActions: FunctionComponent<{
    userId?: string;
    user?: IUserModel;
    setUser: (user: IUserModel) => void;
    userVerification?: IUserVerification;
    setUserVerification: (userVerification: IUserVerification) => void;
    onBan: () => void;
    onRequestChanges: () => void;
}> = ({userId, user, setUser, userVerification, setUserVerification, onBan, onRequestChanges}) => {
    return (
        <Card sx={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
            <Button onClick={async () => {
                const updates = await userService.toggleUserReviewed(userId!, !user?.isReviewed);
                setUser({
                    ...user,
                    ...updates,
                } as IUserModel);
            }}>{user?.isReviewed ? 'Reviewed' : 'Mark as reviewed'}</Button>

            <Button onClick={async () => {
                const updates = await userService.toggleUserApproval(userId!, !user?.isApproved, !!user?.changesRequested);
                setUser({
                    ...user,
                    ...updates,
                } as IUserModel);
            }}>{user?.isApproved ? 'Approved' : 'Mark as approved'}</Button>

            <Button onClick={() => onRequestChanges()}>
                {user?.changesRequested ? 'Changes requested' : 'Request Changes'}
            </Button>

            <Button color={'success'} onClick={async () => {
                const updates = await userVerificationService.toggleUserVerification(userId!, !userVerification?.isVerified);
                setUserVerification({
                    ...userVerification,
                    ...updates,
                } as IUserVerification);
            }}>{userVerification?.isVerified ? 'Profile Verified' : 'Verify Profile'}</Button>

            <Button color={'error'} disabled={!userVerification?.isInReview && !userVerification?.isVerified}
                    onClick={async () => {
                        if (userVerification?.isInReview || userVerification?.isVerified) {
                            await userVerificationService.denyUserVerification(userId!);
                        }

                        setUserVerification({
                            ...userVerification,
                            isVerified: false,
                            isInReview: false
                        } as IUserVerification);
                    }}>{!userVerification?.isInReview && !userVerification?.isVerified ? 'Verification Denied' : 'Deny Verification'}</Button>

            <Button color={'error'} onClick={() => onBan()}>Ban User</Button>
        </Card>
    );
}