import {FunctionComponent} from "react";
import {IPrompt} from "../../../../types/Prompt";
import {
    Button,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";

export const PromptList: FunctionComponent<{
    prompts?: IPrompt[];
    onRemove?: (prompt: IPrompt) => void
}> = ({ prompts = [], onRemove }) => {
    return (
        <Card sx={{mb: 4}}>
            <Typography variant={'h2'} sx={{m: 2}}>Prompts</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Question</TableCell>
                            <TableCell>Answer</TableCell>
                            {onRemove && (
                                <TableCell></TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prompts.map((prompt) => (
                            <TableRow key={prompt.prompt}>
                                <TableCell>{prompt.prompt}</TableCell>
                                <TableCell>{prompt.answer}</TableCell>
                                {onRemove && (
                                    <TableCell>
                                        <Button variant={'outlined'} onClick={() => onRemove(prompt)}
                                                color={'error'}>Remove</Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}