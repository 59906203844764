import Login from './components/pages/Login';
import {Route, Routes} from 'react-router';
import AuthenticatedApp from './components/AuthenticatedApp';
import Reports from "./components/pages/Reports";
import ReportDetail from "./components/pages/ReportDetail/ReportDetail";
import { Users } from './components/pages/Users';
import {UserReviewList} from "./components/pages/UserReviewList";
import {Navigate} from "react-router-dom";
import UserDetail from "./components/pages/UserDetail/UserDetail";
import UserReviewDetail from "./components/pages/UserReview/UserReviewDetail";
import {ActivationList} from "./components/pages/Activations/ActivationList";
import {ActivationEdit} from "./components/pages/Activations/ActivationEdit";
import { DateCardsOrdersList } from './components/pages/DateCards/DateCardsList';
import {UserVerificationList} from "./components/pages/ProfileVerification/UserVerificationList";
import {UserVerificationDetail} from "./components/pages/ProfileVerification/UserVerificationDetail";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route element={<AuthenticatedApp/>}>
                    <Route path={'/reports'} element={<Reports/>}/>
                    <Route path={'/reports/:reportId'} element={<ReportDetail/>}/>
                    <Route path={'/users'} element={<Users/>}/>
                    <Route path={'/users/:userId'} element={<UserDetail/>}/>
                    <Route path={'/user-review'} element={<UserReviewList/>}/>
                    <Route path={'/user-review/:userId'} element={<UserReviewDetail/>}/>
                    <Route path={'/activations'} element={<ActivationList/>}/>
                    <Route path={'/activations/add'} element={<ActivationEdit/>}/>
                    <Route path={'/activations/:activationId'} element={<ActivationEdit/>}/>
                    <Route path={'/date-cards'} element={<DateCardsOrdersList/>}/>
                    <Route path={'/profile-verifications'} element={<UserVerificationList/>}/>
                    <Route path={'/profile-verifications/:userId'} element={<UserVerificationDetail/>}/>
                    <Route path={'*'} element={<Navigate to={'/reports'}/>}/>
                </Route>
                <Route path={"/login"} element={<Login/>}/>
            </Routes>
        </div>
    );
}

export default App;
