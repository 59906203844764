import {Box, Button} from "@mui/material";
import React from "react";

export const ImageHover: React.FC<{
    onClick: () => void;
}> = ({ onClick }) => {
    return (
        <Box sx={{
            '&:hover': {opacity: 1},
            position: 'absolute',
            opacity: 0,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            transition: 'opacity 200ms ease-in',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            'z-index': 50
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: '#000000',
                opacity: 0.2,
            }}/>
            <Button
                variant={'contained'}
                color={"error"}
                onClick={() => onClick()}>
                Delete Image
            </Button>
        </Box>
    );
}