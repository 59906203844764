import {ReportReason} from "./ReportReason";


export const ReasonMap = {
    [ReportReason.FAKE_PROFILE]: "Fake Profile",
    [ReportReason.UNDER_18]: "Underage user",
    [ReportReason.NUDITY]: "Nudity",
    [ReportReason.ABUSE]: "Abusive/threatening behavior",
    [ReportReason.PHYSICAL_HARM]: "In-person physical harm",
    [ReportReason.OTHER]: "Other"
}