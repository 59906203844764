import {FunctionComponent, useEffect, useState} from "react";
import {useParams} from "react-router";
import userService from "../../../services/userService";
import {IUserModel} from "../../../types/UserModel";
import userVerificationService from "../../../services/userVerificationService";
import {IUserVerification} from "../../../types/UserVerification";
import {Box, Button, Card, Grid, ImageList, ImageListItem, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {NavigateBefore} from "@mui/icons-material";
import {differenceInYears} from "date-fns";


export const UserVerificationDetail: FunctionComponent = () => {
    const {userId} = useParams<{ userId: string }>();
    const [user, setUser] = useState<IUserModel>();
    const [userVerification, setUserVerification] = useState<IUserVerification>();


    async function getUser() {
        if (userId) {
            const user = await userService.getUser(userId);
            setUser(user);
        }
    }

    async function getUserVerification() {
        if (userId) {
            const userVerification = await userVerificationService.getUserVerification(userId);
            setUserVerification(userVerification);
        }
    }

    useEffect(() => {
        void Promise.all([
            getUser(),
            getUserVerification()
        ]);

    }, []);

    return (
        <>
            <Button variant={'text'} component={Link} to={'/profile-verifications'} startIcon={<NavigateBefore/>}>
                Back
            </Button>

            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 4,
                    flexDirection: {xs: 'column', lg: 'row'}
                }}>
                    <Typography variant={'h1'}
                                sx={{mb: 4}}>{user?.profile.name}, {user?.profile.birthday && differenceInYears(Date.now(), user?.profile.birthday?.toDate())}</Typography>

                    <Box>
                        <Button color={"success"} sx={{mr: 1}} variant={"contained"} onClick={async () => {
                            const updates = await userVerificationService.toggleUserVerification(userId!, !userVerification?.isVerified);
                            setUserVerification({
                                ...userVerification,
                                ...updates,
                            } as IUserVerification);
                        }}>{userVerification?.isVerified ? 'Unverify' : 'Verify'}</Button>

                        <Button color={"error"} variant={"contained"} onClick={async () => {
                            if(userVerification?.isInReview || userVerification?.isVerified) {
                                await userVerificationService.denyUserVerification(userId!);
                            }

                            setUserVerification({
                                ...userVerification,
                                isVerified: false,
                                isInReview: false
                            } as IUserVerification);
                        }}>{!userVerification?.isInReview && !userVerification?.isVerified ? 'Denied' : 'Deny'}</Button>
                    </Box>
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <Card sx={{p: 2}}>
                        <Typography variant={"h2"} sx={{mb: 2}}>User Photos</Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4}}>
                            <Box sx={{
                                width: 256,
                                height: 256,
                                borderRadius: '50%',
                                overflow: 'hidden',
                                backgroundColor: 'grey.300',
                                position: 'relative',
                            }}
                            >
                                {user?.profile.circleProfilePhoto && (
                                    <img width={'100%'} height={'100%'} src={user.profile.circleProfilePhoto} alt=""/>
                                )}
                            </Box>

                        </Box>

                        <Box sx={{
                            height: 256,
                            overflow: 'hidden',
                            backgroundColor: 'grey.300',
                            position: 'relative',
                        }}
                        >
                            <img width={'100%'} height={'100%'} src={user?.profile.coverPhoto} alt=""/>
                        </Box>

                        <ImageList cols={3}>
                            {(user?.profile.imageList ?? []).map((image, idx) => (
                                <ImageListItem key={idx}>
                                    {!image && (
                                        <Box sx={{bgcolor: 'grey.300', width: '100%', height: '100%'}}/>
                                    )}

                                    <img src={image} alt=""/>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card sx={{p: 2}}>
                        <Typography variant={"h2"}>Verification Photo</Typography>

                        <ImageListItem>
                            <img src={userVerification?.verificationImage} alt=""/>
                        </ImageListItem>
                    </Card>
                </Grid>
            </Grid>

        </>
    );
}