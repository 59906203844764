import {Box, Card, Grid, Typography} from "@mui/material";
import userService from "../../../../services/userService";
import {IUserProfile} from "../../../../types/UserProfile";
import {FunctionComponent} from "react";
import {useConfirmDialog} from "../../../../hooks/useConfirmDialog";
import {ProfileInformationSection} from "./ProfileInformationSection";
import {ProfileFields} from "../../../../constants/ProfileFields";


export const ProfileInformation: FunctionComponent<{
    userId: string,
    profile: IUserProfile,
    onChange: (profile: IUserProfile) => void
}> = ({userId, profile, onChange}) => {
    const {openConfirmDialog} = useConfirmDialog();

    return (
        <Card sx={{p: 2, mb: 4}}>
            <Typography variant={'h2'} sx={{mb: 4}}>Profile Information</Typography>

            <Box sx={{mb: 4}}>
                <ProfileInformationSection
                    title={'Bio'}
                    text={profile.bio}
                    onDelete={() => {
                        void openConfirmDialog(async () => {
                            await userService.deleteProfileInfo(userId, ProfileFields.BIO);
                            onChange({...profile, bio: ''});
                        });
                    }}
                />
            </Box>

            <Box sx={{mb: 4}}>
                <ProfileInformationSection
                    title={'Things I\'d love to do'}
                    text={profile.datePreferences?.description ?? ''}
                    onDelete={() => {
                        void openConfirmDialog(async () => {
                            await userService.deleteProfileInfo(userId, ProfileFields.DATE_DESCRIPTION);
                            onChange({...profile, datePreferences: { description: '' } });
                        });
                    }}
                />
            </Box>

            <Grid container>
                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Company'}
                        text={profile.company}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Job Title'}
                        text={profile.jobTitle}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'School'}
                        text={profile.school}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Languages'}
                        text={profile.languages?.join(', ') ?? ''}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Height'}
                        text={profile.height ? `${profile.height.feet}'${profile.height.inches}"` : ''}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Religiion'}
                        text={profile.religion}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Politics'}
                        text={profile.politics}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Star Sign'}
                        text={profile.starSign}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Exercise'}
                        text={profile.exercise}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Cannabis'}
                        text={profile.cannabis}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Drinking'}
                        text={profile.drinking}
                    />
                </Grid>

                <Grid item sm={2} xs={4}>
                    <ProfileInformationSection
                        title={'Smoking'}
                        text={profile.smoking}
                    />
                </Grid>
            </Grid>
        </Card>
    );
}