import {FunctionComponent, useEffect, useState} from "react";
import userReportService from "../../../services/userReportService";
import {IUserReport} from "../../../types/UserReport";
import {useParams} from "react-router";
import UserDetailContent from "../UserDetail/UserDetailContent";
import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import {NavigateBefore} from "@mui/icons-material";


const ReportDetail: FunctionComponent = () => {
    const {reportId} = useParams<{ reportId: string }>();
    const [report, setReport] = useState<IUserReport | undefined>();

    async function getReport() {
        const res = await userReportService.getReport(reportId);
        setReport(res);
    }

    async function resolveReport() {
        await userReportService.resolve(reportId);
        setReport({...report!, isResolved: true});
    }

    useEffect(() => {
        void getReport();
    }, []);

    return (
        <>
            <Box sx={{mb: 4, display: 'flex', justifyContent: 'space-between'}}>
                <Button variant={'text'} component={Link} to={'/reports'} startIcon={<NavigateBefore/>}>
                    Back
                </Button>

                <Button variant={'contained'} color={'primary'} disabled={!report || report?.isResolved} onClick={resolveReport}>Resolve Report</Button>
            </Box>
            {report?.reportedUserId && <UserDetailContent userId={report?.reportedUserId}/>}
        </>
    )

}

export default ReportDetail;