export function formatTimestamp(timestamp: string): string {
    if (timestamp != null) {
        const date = new Date(timestamp);
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });

        return `${formattedDate} ${formattedTime}`;
    }

    return '';
}