import {collection, CollectionReference, getDocs, query, QuerySnapshot} from "firebase/firestore";
import FirebaseService from "./firebaseService";
import {IAvailableLocation} from "../types/AvailableLocation";


class AvailableLocationService {
    ref: CollectionReference;

    constructor() {
        const db = FirebaseService.db;
        this.ref = collection(db, 'AvailableLocation');
    }

    async getAvailableLocations(): Promise<IAvailableLocation[]> {
        const res = await getDocs(query(this.ref));

        return this._toAvailableLocations(res);
    }

    private _toAvailableLocations(snapshots: QuerySnapshot): IAvailableLocation[] {
        return snapshots.docs.filter((doc) => doc.exists()).map((doc) => {
            return {...doc.data(), id: doc.id} as IAvailableLocation;
        });
    }
}

export default new AvailableLocationService();