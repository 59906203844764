import {
    collection,
    CollectionReference, deleteDoc, doc, getDoc,
    getDocs,
    orderBy,
    query,
    QuerySnapshot, setDoc,
    Timestamp,
    where
} from "firebase/firestore";
import FirebaseService from "./firebaseService";
import {IActivation} from "../types/Activation";


class ActivationService {
    ref: CollectionReference;

    constructor() {
        const db = FirebaseService.db;
        this.ref = collection(db, 'Activation');
    }

    async getActivations(): Promise<IActivation[]> {
        const q = query(this.ref,
            where('datetime', '>=', Timestamp.now()),
            orderBy('datetime', 'asc')
        );

        const docs = await getDocs(q);

        return this._toActivations(docs);
    }

    async getActivation(activationId: string): Promise<IActivation | undefined> {
        const activationSnapshot = await getDoc(doc(this.ref, activationId));

        if(activationSnapshot.exists()) {
            const data = activationSnapshot.data();

            return {...data, id: activationSnapshot.id} as IActivation;
        }

        return undefined;
    }

    async createActivation(activation: IActivation): Promise<string> {
        const ref = doc(this.ref);
        await setDoc(ref, activation, { merge: true });

        return ref.id;
    }

    async saveActivation(activationId: string, activation: IActivation): Promise<void> {
        if(!activationId) {
            return;
        }

        await setDoc(doc(this.ref, activationId), activation, { merge: true });
    }

    async deleteActivation(activationId?: string): Promise<void> {
        if(!activationId) {
            return;
        }

        await deleteDoc(doc(this.ref, activationId));
    }


    private _toActivations(snapshots: QuerySnapshot): IActivation[] {
        return snapshots.docs.filter((doc) => doc.exists()).map((doc) => {
            return {...doc.data(), id: doc.id} as IActivation;
        })
    }

}

export default new ActivationService();