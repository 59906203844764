import {FunctionComponent} from "react";
import {Box, IconButton, Typography} from "@mui/material";
import {DeleteOutline} from "@mui/icons-material";

export const ProfileInformationSection: FunctionComponent<{
    title: string,
    text: string,
    onDelete?: () => void
}> = ({ title, text, onDelete}) => {
    return (
        <Box sx={{mb: 2, display: 'flex', alignItems: 'flex-start'}}>
            {onDelete && (
                <IconButton sx={{mr: 1}} onClick={onDelete} size={"small"}>
                    <DeleteOutline color={"error"}/>
                </IconButton>
            )}

            <Box>
                <Typography variant={"subtitle2"}>{title}</Typography>
                {text && text.length && <Typography>{text}</Typography>}
                {(!text || !text.length) && <Typography sx={{color: 'grey.600', fontStyle: 'italic'}} variant={"body2"}>No answer</Typography>}
            </Box>
        </Box>
    )
}