import {BASE_URL} from "../constants/url";
import FirebaseService from "../services/firebaseService";
import { getAuth } from 'firebase/auth';

export async function sendUserNotification(userId: string, payload: { message: string; title: string; data?: Record<string, unknown>}) {
    const auth = getAuth(FirebaseService.app);
    const idToken = await auth.currentUser?.getIdToken();

    try {
        await fetch(`${BASE_URL}/admin/notifyUser`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ ...payload, userId }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`
            }
        });
    } catch (e) {
        console.error(e);
    }

}