import {FunctionComponent} from "react";
import {useParams} from "react-router";
import UserDetailContent from "../UserDetail/UserDetailContent";
import {Box, Button} from "@mui/material";
import {Link} from "react-router-dom";
import {NavigateBefore} from "@mui/icons-material";


const UserReviewDetail: FunctionComponent = () => {
    const params = useParams<{ userId: string }>();

    return (
        <>
            <Box sx={{mb: 4, display: 'flex', justifyContent: 'space-between'}}>
                <Button variant={'text'} component={Link} to={'/user-review'} startIcon={<NavigateBefore/>}>
                    Back
                </Button>
            </Box>
            <UserDetailContent userId={params.userId}/>
        </>
    )

}

export default UserReviewDetail;