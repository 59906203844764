import React, {useEffect, useState} from "react";
import {getAuth, User} from "firebase/auth";
import {useNavigate} from "react-router";
import {UserRole} from "../constants/UserRole";
import FirebaseService from "../services/firebaseService";
import userService from "../services/userService";

export const AuthContext = React.createContext<{
    user: User | null,
    hasLoaded: boolean,
    role: UserRole,
    signOut: () => void
}>({ user: null, hasLoaded: false, signOut: () => {}, role: UserRole.NONE });

export const AuthContextProvider: React.FunctionComponent<{ children: React.ReactElement }> = ({children}) => {
    const [user, setUser] = useState<User | null>(null);
    const [role, setRole] = useState<UserRole>(UserRole.NONE);
    const [hasLoaded, setHasLoaded] = useState(false);
    const navigate = useNavigate();

    async function signOut() {
        await getAuth(FirebaseService.app).signOut();
        setRole(UserRole.NONE);
        setUser(null);
        navigate('/login');
    }


    useEffect(() => {
        const auth = getAuth(FirebaseService.app);
        auth.onAuthStateChanged(async (newUser) => {
            if(newUser) {
                const role = await userService.getRole(newUser.uid);
                setUser(newUser);
                setRole(role);
            } else {
                setUser(null);
                setRole(UserRole.NONE);
                navigate('/login');
            }

            setHasLoaded(true);
        });
    }, []);

    return (
        <AuthContext.Provider value={{user, role, hasLoaded, signOut}}>
            <>{children}</>
        </AuthContext.Provider>
    )
}