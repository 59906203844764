import {ImageHover} from "./ImageHover";
import userService from "../../../../services/userService";
import {Box} from "@mui/material";
import {useConfirmDialog} from "../../../../hooks/useConfirmDialog";
import {FunctionComponent} from "react";

export const CoverPhoto: FunctionComponent<{ userId: string, coverPhoto: string; onChange: () => void}> = ({ userId, coverPhoto, onChange }) => {
    const { openConfirmDialog } = useConfirmDialog();


    return (
        <Box sx={{
            height: 256,
            overflow: 'hidden',
            backgroundColor: 'grey.300',
            position: 'relative',
        }}
        >
            {coverPhoto && (
                <ImageHover
                    onClick={() => {
                        void openConfirmDialog(async () => {
                            await userService.deleteCoverPhoto(userId, coverPhoto);
                            onChange();
                        });
                    }}
                />
            )}

            {coverPhoto && (
                <img width={'100%'} height={'100%'} src={coverPhoto} alt=""/>
            )}
        </Box>
    );
}