import {createTheme} from "@mui/material";
import RalewayBold from '../fonts/Raleway-Bold.ttf';
import Poppins from '../fonts/Poppins-Regular.ttf';


const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Raleway-Bold';
                  font-style: bold;
                  font-display: swap;
                  font-weight: 600;
                  src: local('Raleway'), local('Raleway'), url(${RalewayBold}) format('truetype');
                }
                
                @font-face {
                  font-family: 'Poppins';
                  font-style: normal;
                  font-display: swap;
                  font-weight: 400;
                  src: local('Poppins'), local('Poppins-Regular'), url(${Poppins}) format('truetype');
                }
           `,
        },
    },
    typography: {
        fontFamily: 'Poppins',
        h1: {
            fontFamily: 'Raleway-Bold',
            fontSize: 42,
        },
        h2: {
            fontFamily: 'Raleway-Bold',
            fontSize: 30,
        },
        h3: {
            fontFamily: 'Raleway-Bold',
            fontSize: 24,
        },
    },
    palette: {
        background: {
            default: '#F5F5F5'
        },
        primary: {
            main: '#2196f3',
            light: '#6ec6ff',
            dark: '#0069c0'
        },
        secondary: {
            main: '#c8e6c9',
            light: '#fbfffc',
            dark: '#97b498'
        }
    }
});

export default theme;