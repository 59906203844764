import {FunctionComponent} from "react";
import {Box, Button, Modal, Typography} from "@mui/material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ConfirmDialog: FunctionComponent<{
    isOpen: boolean;
    onClose: (res?: boolean) => void;
    itemName?: string;
}> = ({ isOpen = false, onClose, itemName = ''}) => {
    return (
        <Modal
            onClose={() => onClose()}
            open={isOpen}>
            <Box sx={style}>
                <Typography variant={"h6"} sx={{ mb: 2 }}>Are you sure?</Typography>
                <Typography sx={{ mb: 6 }}>
                    This action cannot be undone and the user will be notified
                </Typography>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Button variant={"text"} sx={{ mr: 1 }} onClick={() => onClose()}>Cancel</Button>
                    <Button variant={"contained"} color={"error"} onClick={() => onClose(true)}>Delete {itemName}</Button>
                </Box>
            </Box>

        </Modal>
    )
}

export default ConfirmDialog;