import {FunctionComponent, useEffect, useState} from "react";
import {
    Box, Card,
    Typography
} from "@mui/material";
import { IUserReport } from "../../types/UserReport";
import userReportService from "../../services/userReportService";
import ReportList from "../common/ReportList";


const Reports: FunctionComponent = () => {
    const [reports, setReports] = useState<IUserReport[]>([]);

    async function getReports() {
        const res = await userReportService.getUnresolvedReports();
        setReports(res);
    }

    useEffect(() => {
        void getReports();
    }, []);

    return (
        <Box>
            <Typography variant={"h1"} sx={{ mb: 4 }}>
                Reported Users
            </Typography>

            <Card sx={{ mb: 4 }}>
                <ReportList reports={reports}/>
            </Card>

        </Box>
    );
}

export default Reports;
