import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import activationService from "../../../services/activationService";
import {IActivation} from "../../../types/Activation";
import {Timestamp} from "firebase/firestore";
import {Box, Button, Card, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {format, parseISO} from "date-fns";
import {States} from "../../../constants/States";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import firebaseService from "../../../services/firebaseService";
import availableLocationService from "../../../services/availableLocationService";
import {IAvailableLocation} from "../../../types/AvailableLocation";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";


export const ActivationEdit: React.FC = () => {
    const navigate = useNavigate();
    const [file, setFile] = useState<File>();
    const [availableLocations, setAvailableLocations] = useState<Record<string, IAvailableLocation>>({});
    const [preview, setPreview] = useState<string | undefined>();
    // const [_, setUploadPercent] = useState(0);
    const [activation, setActivation] = useState<IActivation>({
        description: "",
        image: "",
        url: "",
        title: '',
        availableLocationId: '',
        location: {
            address: '',
            city: '',
            state: '',
            zip: ''
        },
        datetime: Timestamp.now()
    });
    const {activationId} = useParams<{ activationId?: string }>();
    const isEditing = !!activationId;

    async function getActivation() {
        if (activationId) {
            const res = await activationService.getActivation(activationId);

            if (res) {
                setActivation(res);
            }
        }
    }

    async function getAvailableLocations() {
        const res = await availableLocationService.getAvailableLocations();
        setAvailableLocations(res.reduce<Record<string, IAvailableLocation>>((obj, loc) => {
            obj[loc.id] = loc;

            return obj;
        }, {}));
    }

    async function saveActivation() {
        const image = await uploadFile();

        const newActivation = {
            ...activation,
            image
        };

        setActivation(newActivation);

        if (isEditing) {
            await activationService.saveActivation(activationId, newActivation);
        } else {
            await activationService.createActivation(newActivation);
        }

        navigate(`/activations`);
    }

    function uploadFile(): Promise<string> {
        if (!file) {
            return Promise.resolve('');
        }

        return new Promise((resolve, reject) => {
            const splitFile = file.name.split('.');
            const fileEnding = splitFile.length > 1 ? splitFile[splitFile.length - 1] : '';
            const storageRef = ref(firebaseService.storage, `/ActivationImages/${Timestamp.now().seconds}.${fileEnding}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (/*snapshot*/) => {
                    // const percent = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // setUploadPercent(percent);
                },
                (err) => reject(err),
                async () => {
                    const image = await getDownloadURL(uploadTask.snapshot.ref);

                    setActivation({
                        ...activation,
                        image
                    });

                    setFile(undefined);

                    resolve(image);
                }
            )
        })

    }

    useEffect(() => {
        getAvailableLocations();
        if (isEditing) {
            void getActivation();
        }
    }, [isEditing]);

    useEffect(() => {
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            setPreview(objectUrl);

            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [file]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
                <Typography variant={'h1'} sx={{mb: 4}}>{isEditing ? 'Edit' : 'Add'} Activation</Typography>
                <Card sx={{p: 4}}>
                    <Box sx={{display: 'flex', mb: 3}}>
                        <TextField
                            sx={{mr: 1}}
                            label={'Date'}
                            type={"date"}
                            value={format(activation.datetime.toDate(), 'yyyy-MM-dd')}
                            onChange={(e) => {
                                setActivation({
                                    ...activation,
                                    datetime: Timestamp.fromDate(parseISO(e.target.value))
                                })
                            }}/>

                        <TimePicker
                            label={"Time"}
                            value={activation.datetime.toDate()}
                            onChange={(value) => {
                                if (value) {
                                    setActivation({
                                        ...activation,
                                        datetime: Timestamp.fromDate(value)
                                    })
                                }
                            }}
                        />
                    </Box>


                    <Box sx={{display: 'flex', mb: 3}}>
                        <TextField
                            sx={{mr: 1}}
                            fullWidth={true}
                            label={'Title'}
                            value={activation.title}
                            onChange={(e) => {
                                setActivation({
                                    ...activation,
                                    title: e.target.value
                                })
                            }}/>

                        <FormControl fullWidth={true}>
                            <InputLabel id={'launch-location-input-label'}>Launch Location</InputLabel>
                            <Select
                                label={'Launch Location'}
                                labelId={'launch-location-input-label'}
                                sx={{mr: 1}}
                                value={activation.availableLocationId}
                                onChange={(e) => {
                                    setActivation({
                                        ...activation,
                                        availableLocationId: e.target.value
                                    })
                                }}>
                                {Object.values(availableLocations).map((loc) => <MenuItem key={loc.id}
                                                                                          value={loc.id}>{loc.name}</MenuItem>)}
                            </Select>
                        </FormControl>

                    </Box>

                    <TextField
                        sx={{mb: 3}}
                        fullWidth={true}
                        label={'URL'}
                        value={activation.url}
                        onChange={(e) => {
                            setActivation({
                                ...activation,
                                url: e.target.value
                            })
                        }}/>

                    <TextField
                        sx={{mb: 1}}
                        fullWidth={true}
                        label={'Address'}
                        value={activation.location.address}
                        onChange={(e) => {
                            setActivation({
                                ...activation,
                                location: {
                                    ...activation.location,
                                    address: e.target.value
                                }
                            })
                        }}/>

                    <Box sx={{display: 'flex', mb: 3}}>
                        <TextField
                            fullWidth={true}
                            label={'City'}
                            sx={{mr: 1}}
                            value={activation.location.city}
                            onChange={(e) => {
                                setActivation({
                                    ...activation,
                                    location: {
                                        ...activation.location,
                                        city: e.target.value
                                    }
                                })
                            }}/>

                        <FormControl sx={{minWidth: 100}}>
                            <InputLabel id={'state-input-label'}>State</InputLabel>
                            <Select
                                label={'State'}
                                labelId={'state-input-label'}
                                sx={{mr: 1}}
                                value={activation.location.state}
                                onChange={(e) => {
                                    setActivation({
                                        ...activation,
                                        location: {
                                            ...activation.location,
                                            state: e.target.value
                                        }
                                    })
                                }}>
                                {States.map((state) => <MenuItem key={state} value={state}>{state}</MenuItem>)}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth={true}
                            label={'Zip code'}
                            value={activation.location.zip}
                            onChange={(e) => {
                                setActivation({
                                    ...activation,
                                    location: {
                                        ...activation.location,
                                        zip: e.target.value
                                    }
                                })
                            }}/>
                    </Box>


                    <TextField
                        sx={{mb: 3}}
                        fullWidth={true}
                        label={'Description'}
                        value={activation.description}
                        multiline={true}
                        rows={4}
                        onChange={(e) => {
                            setActivation({
                                ...activation,
                                description: e.target.value
                            })
                        }}/>

                    <Box sx={{mb: 2}}>
                        <img src={preview ?? activation.image} alt=""
                             style={{'maxHeight': '400px', 'maxWidth': '100%'}}/>
                    </Box>

                    <Button variant={'outlined'} component={'label'}>
                        Upload Image
                        <input hidden type="file" accept={"image/*"} onChange={(e) => {
                            if (e.target.files?.length) {
                                setFile(e.target.files[0]);
                            }
                        }}/>
                    </Button>

                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                void saveActivation();
                            }}
                        >Save</Button>
                    </Box>

                </Card>
            </Box>
        </LocalizationProvider>
    );
}