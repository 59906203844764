import {FunctionComponent, useEffect, useState} from "react";
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow, Typography
} from "@mui/material";
import {Link} from "react-router-dom";
import {IUserVerification} from "../../../types/UserVerification";
import profileVerificationService from "../../../services/userVerificationService";


export const UserVerificationList: FunctionComponent = () => {
    const pageSize = 25;
    const [page, setPage] = useState(0);
    const [requests, setRequests] = useState<IUserVerification[]>([]);

    async function getProfileVerificationRequests(): Promise<void> {
        const requests = await profileVerificationService.getUnreviewedRequests();
        setRequests(requests);
    }

    useEffect(() => {
        void getProfileVerificationRequests();
    }, []);

    return (
        <Box sx={{width: '100%'}}>
            <Typography variant={"h1"} sx={{ mb: 4 }}>
                User Profile Verification
            </Typography>
            <Card>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User ID</TableCell>
                                <TableCell>Requested On</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {requests.slice(page * pageSize, page * pageSize + pageSize).map((row) => (
                                <TableRow
                                    component={Link}
                                    to={`/profile-verifications/${row.id}`}
                                    key={row.id}
                                    role={"link"}
                                    hover={true}
                                    sx={{'cursor': 'pointer', 'textDecoration': 'none'}}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>
                                        <span>{row.submittedOn.toDate().toLocaleString() ?? ''}</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component={"div"}
                    count={requests.length}
                    page={page}
                    rowsPerPage={pageSize}
                    onPageChange={(event, newPage) => setPage(newPage)}/>
            </Card>
        </Box>
    );
}