import {FunctionComponent, useEffect, useState} from "react";
import {IUserProfile} from "../../types/UserProfile";
import userService from "../../services/userService";
import {
    Box,
    Card, Input,
    Typography
} from "@mui/material";
import {UserList} from "../common/UserList";

let debounceTimer: NodeJS.Timeout;

export const Users: FunctionComponent = () => {
    const [search, setSearch] = useState<string>('');
    const [users, setUsers] = useState<IUserProfile[]>([]);

    async function getUsers() {
        const res = await userService.getUserProfiles(search);
        setUsers(res);
    }

    useEffect(() => {
        if(debounceTimer) {
            clearTimeout(debounceTimer);
        }

        debounceTimer = setTimeout(() => {
            void getUsers();
        }, 300);

    }, [search]);

    return (
        <Box>
            <Typography variant={'h1'} sx={{mb: 4}}>
                Users
            </Typography>

            <Card>
                <Input
                    value={search}
                    placeholder={"Search..."}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{ m: 2}}
                />

                <UserList users={users} showName={true}/>
            </Card>
        </Box>
    )
}