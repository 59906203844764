import {FunctionComponent, useState} from "react";
import {IUserProfile} from "../../types/UserProfile";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import {IUserModel} from "../../types/UserModel";
import {Link} from "react-router-dom";

export const UserList: FunctionComponent<{
    users: IUserProfile[] | IUserModel[],
    showName?: boolean,
    to?: string,
    showLoginTime?: boolean
}> = ({users, showName, showLoginTime, to}) => {
    const pageSize = 25;
    const [page, setPage] = useState(0);

    return (
        <Box sx={{width: '100%'}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {showName && <TableCell>Name</TableCell>}
                            <TableCell>User ID</TableCell>
                            {showLoginTime && <TableCell>Last Login Time</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.slice(page * pageSize, page * pageSize + pageSize).map((row) => (
                            <TableRow
                                component={Link}
                                to={`/${to ?? 'users'}/${row.id}`}
                                key={row.id}
                                role={"link"}
                                hover={true}
                                sx={{'cursor': 'pointer', 'textDecoration': 'none'}}>
                                {showName && <TableCell>{(row as IUserProfile).name}</TableCell>}
                                <TableCell>{row.id}</TableCell>
                                {showLoginTime && (
                                    <TableCell>
                                        <span>{(row as IUserModel).lastLoginTime?.toDate().toLocaleString() ?? ''}</span>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component={"div"}
                count={users.length}
                page={page}
                rowsPerPage={pageSize}
                onPageChange={(event, newPage) => setPage(newPage)}/>
        </Box>
    );
}