import {FunctionComponent, useState} from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {IUserReport} from "../../types/UserReport";
import {ReasonMap} from "../../constants/ReasonMap";
import {Link} from "react-router-dom";


const ReportList: FunctionComponent<{
    reports: IUserReport[];
    pageSize?: 10 | 25 | 50 | 100;
}> = ({reports, pageSize = 25}) => {
    const [page, setPage] = useState(0);

    return (
        <Box sx={{width: '100%'}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant={'head'}>User ID</TableCell>
                            <TableCell variant={'head'}>Reason</TableCell>
                            <TableCell variant={'head'}>Reported On</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reports.slice(page * pageSize, page * pageSize + pageSize).map((row) => (
                            <TableRow component={Link} key={row.id} to={`/reports/${row.id}`} role={"link"} hover={true} sx={{
                                'cursor': 'pointer',
                                textDecoration: 'none'
                            }}>
                                <TableCell>{row.reportedUserId}</TableCell>
                                <TableCell>{ReasonMap[row.reason]}</TableCell>
                                <TableCell>{row.reportedOn.toDate().toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component={"div"}
                count={reports.length}
                page={page}
                rowsPerPage={pageSize}
                onPageChange={(event, newPage) => setPage(newPage)}/>
        </Box>
    );
}

export default ReportList;