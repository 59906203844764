import {FirebaseApp, initializeApp} from "firebase/app";
import {getFirestore, Firestore} from "firebase/firestore";
import {FirebaseOptions} from "@firebase/app";
import {FirebaseStorage, getStorage} from "firebase/storage";


const firebaseConfig: FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class FirebaseService {
    public readonly app: FirebaseApp;

    constructor() {
        this.app = initializeApp(firebaseConfig);
    }

    get db(): Firestore {
        return getFirestore(this.app);
    }

    get storage(): FirebaseStorage {
        return getStorage(this.app);
    }
}

export default new FirebaseService();